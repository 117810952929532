import { BeforeEnterObserver, RouterLocation } from "@vaadin/router";
import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { View } from "../view";
import { SignatureEndpoint, SubscriptionEndpoint } from "Frontend/generated/endpoints";
import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";

@customElement("signed-document-success")
export class SignedDocumentSuccess extends View implements BeforeEnterObserver {

    _collectId: string | null = "";
    _signatureRequestId: string | null = "";

    @state()
    subscription!: Subscription;

    onBeforeEnter(location: RouterLocation) {
        // this.collectId = location.params.collectId as string;
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("external_id")) {
                this._collectId = searchParams.get("external_id");
            }
            if(searchParams.has("signature_request_id")){
                this._signatureRequestId = searchParams.get("signature_request_id");
            }
        }

    }

    render() {
        if (this.subscription !== undefined) {
            return html`
            <h3 style="width:100%">Documents signés</h3>
            <p>Vos documents ont bien été signés.</p>
            `;
        } else {
            return html`
            `
        }
    }

    async getSubscription() {
        if (this._collectId != null) {
            var serverResponse = await SubscriptionEndpoint.getSubscriptionByCollectId(this._collectId, "", window.sessionStorage.getItem("auth-data") as string);
            if (serverResponse != undefined) {
                this.subscription = serverResponse;
            }
        }

    }

    connectedCallback() {
        super.connectedCallback();
        this.classList.add(
            'w-full'
        );
        this.getSubscription();
        if(this._collectId != null && this._signatureRequestId != null){
            SignatureEndpoint.retrieveDocuments(this._collectId, this._signatureRequestId);
        }
    }

}