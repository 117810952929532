import '@cdui/button';
import '@cdui/document';
import '@cdui/documents-section-header';
import '@cdui/nav';
import { Notification } from '@vaadin/notification';
import "@vaadin/upload";
import { FileUploadEndpoint, SignatureEndpoint, SubscriptionEndpoint } from "Frontend/generated/endpoints";
import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";
import { appStore } from "Frontend/stores/app-store";
import { LitElement, css, html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import './docs-by-category';
import './document-upload';
import './global-drop-zone';
import './my-information';
import { StepChangedEvent } from "./step-event-dispatcher";
import './sign-docs-form';
import '../nav/cdui-nav';
import { CduiNavElement } from '../nav/cdui-nav';
import { BeforeEnterObserver, PreventAndRedirectCommands, Router, RouterLocation } from '@vaadin/router';
import './DocumentsSummary';
import { applyTheme } from 'Frontend/generated/theme';

@customElement("document-view")
export class DocumentView extends LitElement implements BeforeEnterObserver {

    @state()
    collectId: string = "";

    _from: string = "";

    _signatureRequestId: string = "";

    @state()
    uploadInProgress: boolean = false;

    @state()
    subscription: Subscription = {};

    @state()
    statusByDocument: Map<string, boolean> = new Map();

    @state()
    private _currentStep: number = 0;

    @state()
    private _validation: boolean = false;


    onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ) {
        if (window.sessionStorage.getItem("auth-data") == null) {
            return commands.redirect("/auth/" + encodeURIComponent(location.params.collectId as string) + "/docs");
        } else {
            return;
        }
    }

    render() {
        window.onbeforeunload = function () {
            return "Même si vos documents n'ont pas été soumis, ils seront conservés en l'état jusqu'à soumission.";
        };
        //calculate currentStep before rendering
        var allCatsList = Array.from(new Set(this.subscription.missingDocuments?.map(doc => doc?.category)).add("others")).filter(cat => cat !== undefined) as string[];

        var priorityDocsToCollect = this.subscription.missingDocuments !== undefined ? this.getTotalDocumentsToCollect(this.subscription.missingDocuments.filter(document => document !== undefined && document.priority && !document.toSign && document.category !== "signature" && !document.collected)) : 0;
        var otherDocsToCollect = this.subscription.missingDocuments !== undefined ? this.getTotalDocumentsToCollect(this.subscription.missingDocuments.filter(document => document !== undefined && !document.priority && !document.toSign && !document.collected)) : 0;
        var docsToSign = this.subscription.missingDocuments !== undefined ? this.subscription.missingDocuments?.filter(md => md?.toSign).length : 0;
        var hasPriorityDocs = allCatsList && priorityDocsToCollect > 0;
        var hasOtherDocs = otherDocsToCollect > 0;
        var hasDocsToSign = docsToSign > 0;

        var navElements: CduiNavElement[] = [
            {
                title: "Mes informations",
                hasDocs: false
            },
            {
                title: "Documents prioritaires",
                hasDocs: hasPriorityDocs,
                docsToCollect: priorityDocsToCollect
            },
            {
                title: "Autres documents",
                hasDocs: hasOtherDocs,
                docsToCollect: otherDocsToCollect
            },
            {
                title: "Signature documents",
                hasDocs: hasDocsToSign,
                docsToCollect: docsToSign
            },
            {
                title: "Confirmation & envoi",
                hasDocs: false
            }
        ]

        var retstr = html`
            <div class="cdui-page">
                <cdui-nav-v2 .navElements=${navElements} @step-changed=${this.changeStep} .currentStep=${this._currentStep}>
                    <img slot="logo" src="images/logo.png">
                    <img slot="small-logo" src="images/logo_only.png">
                </cdui-nav-v2>

                <div style="width: 100%;overflow-y:auto;overflow-x:hidden">
                    <vaadin-vertical-layout .hidden=${this._currentStep != 0}>
                        <my-information style="width:100%" .subscription=${this.subscription} @step-changed=${this.changeStep}></my-information>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout>
                        <div .hidden=${this._currentStep != 1} style="width:100%"><h1 class="cdui-title">${this.subscription.externalId} - Documents PRIORITAIRES à joindre au dossier</h1></div>
                        <div .hidden=${this._currentStep != 2} style="width:100%"><h1 class="cdui-title">${this.subscription.externalId} - Documents additionnels à joindre au dossier</h1></div>

                        <div .hidden=${![1, 2].includes(this._currentStep)} class="mobile-hide" style="width:100%" ><li class="cdui-subtitle">
                            Voici les pièces réclamées par l'organisme ${this.subscription.tenant?.name} pour la constitution de votre dossier. 
                            Merci de les vérifier et de joindre les pièces manquantes.
                        </li></div>

                        <document-summary class="mobile-hide" .hidden=${![1, 2].includes(this._currentStep)} .requiredDocuments=${this.subscription.missingDocuments as RequiredDocument[]} style="width:100%;"></document-summary>
                        <global-drop-zone  
                            .hidden=${![1, 2].includes(this._currentStep)} 
                            style="width:100%" 
                            .collectId=${this.subscription.collectId !== undefined ? this.subscription.collectId : ""} 
                            .subscription=${this.subscription} 
                            @subscription-changed=${(e: CustomEvent) => this.getSubscription()}
                        >
                        </global-drop-zone>

                        <docs-by-category 
                            .hidden="${this._currentStep != 1}"
                            id="priority-docs"
                            style="width:100%"
                            previousStep="0" 
                            nextStep="2" 
                            .categories=${allCatsList.filter(category => category != "signature" && category != "others")}
                            .priority=${true}
                            .collectId=${this.collectId}
                            .missingDocuments=${this.subscription.missingDocuments as RequiredDocument[]}
                            .displayDocs=${hasPriorityDocs}
                            @step-changed=${this.changeStep}
                        ></docs-by-category>

                        <docs-by-category 
                            .hidden="${this._currentStep != 2}"
                            style="width:100%"
                            id="other-docs"
                            previousStep="1" 
                            nextStep="3" 
                            .categories=${allCatsList.filter(category => category != "signature")}
                            .priority=${false}
                            .collectId=${this.collectId}
                            .missingDocuments=${this.subscription.missingDocuments as RequiredDocument[]}
                            .displayDocs=${true}
                            @step-changed=${this.changeStep}
                        ></docs-by-category>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout .hidden="${this._currentStep != 3}">
                        <sign-docs-form
                            style="width:100%"
                            previousStep="2"
                            nextStep="4"
                            .externalId=${this.subscription.externalId}
                            .collectId=${this.collectId}
                            .docsToSign=${this.subscription.missingDocuments?.filter(doc => doc?.toSign && !doc.uploadedDocument?.sign?.signed)}
                            @step-changed=${this.changeStep}
                        ></sign-docs-form>
                    </vaadin-vertical-layout>

                    <vaadin-vertical-layout id="confirmation-step" .hidden="${this._currentStep != 4}">
                        <h1 class="cdui-title">${this.subscription?.externalId} - Confirmation</h1>

                        <!-- <div class"cdui-documents-list">
                            <cdui-document done class="urgent">RELEVÉ DE COMPTE</cdui-document>
                        </div> -->

                        <div class="cdui-vertical-layout">
                            <div style="display: flex; gap: 10px; align-items: baseline;">
                                <input type="checkbox" id="validation-checkbox" .checked="${this._validation}" @click="${(e: MouseEvent) => this._validation = (e.target! as HTMLInputElement).checked}" />
                                <label for="validation-checkbox">J'autorise la transmission de mes documents.</label>
                            </div>

                            <cdui-button
                                    @click="${() => { if (this._validation) this.uploadFiles() }}"
                                    ?disabled="${!this._validation}"
                            >
                                Transmettre mes documents
                            </cdui-button>

                            <vaadin-vertical-layout .hidden=${!this.uploadInProgress}>
                                <p>Veuillez patienter svp pendant que nous analysons vos documents...</p>
                            </vaadin-vertical-layout>
                        </div>
                    </vaadin-vertical-layout>
                </div>
           </div>
        `;
        return retstr;
    }

    private changeStep(e: StepChangedEvent) {
        this._currentStep = e.detail.step;
    }

    getTotalDocumentsToCollect(requiredDocuments: Array<RequiredDocument | undefined> | undefined) {
        if (requiredDocuments !== undefined && requiredDocuments.length > 0) {
            return requiredDocuments.length;
        } else {
            return 0;
        }
    }

    updated(changedProperties: Map<string, any>) {
        if (changedProperties.has('_currentStep')) {
            this.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    signDocument(e: CustomEvent) {
        var documents = this.subscription.missingDocuments?.filter(doc => doc?.toSign && !doc.uploadedDocument?.sign?.signed);
        SignatureEndpoint.signDocuments(this.subscription.collectId, documents).then(uri => {
            if (uri !== undefined && uri?.length > 0) {
                window.onbeforeunload = function () { };
                window.location.href = uri;
            }
        });
    }

    async uploadFiles() {
        // this.uploadInProgress = true;
        FileUploadEndpoint.startFlow(this.collectId);
        window.onbeforeunload = function () { };
        window.location.href = '/docs-end';
        //disable upload button + display progress bar
        // this.uploadInProgress = false;
        //redirect 

    }

    async getSubscription() {
        if (this._signatureRequestId != null && this._signatureRequestId.length > 0) {
            this._currentStep = 4;
            SignatureEndpoint.retrieveDocuments(this.collectId, this._signatureRequestId).then((response) => {
                SubscriptionEndpoint.getSubscriptionByCollectId(this.collectId, this._from != null ? this._from : "", window.sessionStorage.getItem("auth-data") as string).then((response) => {
                    if (response != undefined) {
                        this.subscription = response;
                    }
                });
            });

        } else {
            var serverResponse = await SubscriptionEndpoint.getSubscriptionByCollectId(this.collectId, this._from != null ? this._from : "", window.sessionStorage.getItem("auth-data") as string);
            if (serverResponse == undefined || serverResponse == null) {
                window.sessionStorage.removeItem("auth-data");
                Router.go('/auth/' + encodeURIComponent(this.collectId) + "/docs");
            }
            if (serverResponse != undefined) {
                this.subscription = serverResponse;
                appStore.setSubscription(serverResponse);
                // this.restoreDocumentsState();
            } else {
                Notification.show("Dossier non trouvé.")
            }
        }
    }

    connectedCallback() {
        super.connectedCallback();
        applyTheme(this.renderRoot);

        this.collectId = location.pathname.split("/").pop() as string;
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("from") && searchParams.get("from") !== null) {
                this._from = searchParams.get("from") as string;
            }
            if (searchParams.has("signature_request_id") && searchParams.get("signature_request_id") !== null) {
                this._signatureRequestId = searchParams.get("signature_request_id") as string;
            }
        }

        this.classList.add(
            'w-full',
            'h-full',
        );
        this.getSubscription();
        
    }
}