import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import { applyTheme } from "Frontend/generated/theme";
import { html, LitElement } from "lit";
import { get, translate } from "lit-translate";
import { customElement, state } from "lit/decorators.js";

@customElement("document-summary")
class DocumentSummary extends LitElement {

    @state()
    requiredDocuments: RequiredDocument[] = [];

    render() {
        return html`
            <div class="cdui-card" style="width: calc(100% - 60px);">
                <p>La liste des documents à transmettre est la suivante :</p>
                <ul>
                    ${Array.from(this.groupBy(this.requiredDocuments.filter(requiredDocument => !requiredDocument.collected), requiredDocument => requiredDocument.category).values()).map(requiredDocumentsByCat => {
                        return html`<li>${requiredDocumentsByCat.length} ${translate("categories." + requiredDocumentsByCat[0].category)}: ${get("categories-explanation."+requiredDocumentsByCat[0].category).replace("%qty%", requiredDocumentsByCat.length.toString())}</li>`;
                    })}
                </ul>
            </div>
        `;
    }

    groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
            var key = grouper(item)
            if (!store.has(key)) {
                store.set(key, [item])
            } else {
                store.get(key)?.push(item)
            }
            return store
        }, new Map<K, V[]>())
    }

    connectedCallback() {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

}