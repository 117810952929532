import RequiredDocument from "Frontend/generated/oxynoia/documents/collection/b2b/model/RequiredDocument";
import { LitElement, html } from "lit";
import { translate } from "lit-translate";
import { customElement, property, state } from "lit/decorators.js";
import { v4 as uuidv4 } from 'uuid';
import { StepEventEventDispatcher } from "./step-event-dispatcher";
import { applyTheme } from "Frontend/generated/theme";
import './document-upload';
import '../nav/documents-section-header';

const maxFileSizeInMB = 10;

@customElement("docs-by-category")
class DocsByCategory extends StepEventEventDispatcher {

    @property({type: Number})
    previousStep: number = 0;

    @property({type: Number})
    nextStep: number = 0;

    @property({type: Array})
    categories: string[] = [];

    @property({type: Boolean})
    priority: boolean = false;

    @property()
    collectId: string = "";

    @state()
    missingDocuments: RequiredDocument[] = [];

    @state()
    displayDocs: boolean = false;

    render() {
        if(this.missingDocuments == undefined){
            this.missingDocuments = [];
        }
        var pagination = html`
            <div class="cdui-pagination">
                <div class="cdui-pagination-link" @click=${() => this.goToStep(this.previousStep)}>
                    <cdui-button class="round">ᐸ</cdui-button>
                    <span class="mobile-hide">Précédent</span>
                </div>
                <cdui-button id="submit" @click=${() => this.goToStep(4)}>Soumettre en l'état</cdui-button>
                <div class="cdui-pagination-link" @click=${() => this.goToStep(this.nextStep)}>
                    <span class="mobile-hide">Suivant</span>
                    <cdui-button class="round">ᐳ</cdui-button>
                </div>
            </div>
        `;
        var docsByCategory = this.groupBy(this.missingDocuments.filter(document => document !== undefined
            && !document.toSign
            && (document.priority === this.priority)), (rd => rd.category));
        return html`
            ${this.displayDocs ? html`
                <p>Taille maximum par fichier: ${maxFileSizeInMB} MB</p>
                <p>Formats acceptés : .pdf, .jpg, .jpeg, .png, .tiff, .tif</p>

                ${pagination}

                <hr/>

                <div class="cdui-documents-list">
                    ${this.categories.map(category => {
                     var documents = (docsByCategory.has(category) && docsByCategory.get(category) !== undefined ? docsByCategory.get(category) : []) as RequiredDocument[];
                    if(documents.length == 0 && category != "others"){
                        return html``;
                    }else{
                        return html`
                        <div id="${category}">
                            <cdui-documents-section-header-v2 .number=${documents.filter(doc => !doc?.collected).length}>
                                ${translate("categories." + category)}
                            </cdui-documents-section-header-v2>
                            <span><div class="mobile-hide">Les pièces acceptées sont les suivantes:</div> ${translate("categories-description." + category)}</span>
                            <div class="cdui-file-inputs-container">
                                ${documents.map(doc => doc as RequiredDocument).map(document => {
                                return html`<document-upload .id=${document.id !== undefined ? document.id : ""} class="cdui-file-upload-container" .document=${document} transactionId=${document.id !== undefined ? document.id : ""} .collectId=${this.collectId}></document-upload>`
                                })}
                                ${category == "others" ? html`
                                    <p>Vous pouvez ajouter des documents supplémentaires en cliquant sur le bouton ci-dessous.</p>
                                    <cdui-button @click=${() => this.addOtherDoc()}>Ajouter un document supplémentaire</cdui-button>
                                    `
                                : html``
                                }
                            </div>
                        </div>
                        `
                    }
                        })}
                    </div>
                    ` : html`<strong>${this.priority ? "Vous n'avez aucun documents prioritaires à transmettre." : "Vous n'avez aucun documents à transmettre."}</strong>`}
            ${pagination}
        `;
    }

    addOtherDoc(m_uid?: string) {
        var docs = this.missingDocuments;
        if (docs == undefined) {
            docs = [];
        }
        var uid = m_uid;
        if (uid == null) {
            uid = uuidv4();
        }

        docs.push({
            id: uid,
            category: "others",
            isManual: true,
            toSign: false,
            collected: false,
            signed: false,
            priority: false
        });
        this.missingDocuments = Array.from(docs);

        console.log("Added doc " + uid);

        return uid;
    }

    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }

    groupBy<K, V>(array: V[], grouper: (item: V) => K) {
        return array.reduce((store, item) => {
          var key = grouper(item)
          if (!store.has(key)) {
            store.set(key, [item])
          } else {
            store.get(key)?.push(item)
          }
          return store
        }, new Map<K, V[]>())
      }


}