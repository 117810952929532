import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BeforeEnterObserver, Router, RouterLocation } from '@vaadin/router';
import { View } from '../../views/view.js';
import { Notification } from '@vaadin/notification';
import * as SubscriptionEndpoint from 'Frontend/generated/SubscriptionEndpoint.js';
import Subscription from 'Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription.js';
import * as AuthEndpoint from 'Frontend/generated/AuthEndpoint.js';
import { appStore } from 'Frontend/stores/app-store.js';
import { MobxLitElement } from '@adobe/lit-mobx';
import '@vaadin/radio-group';
import '@cdui/button';
import "../validation-step/validation-step-view"
import "../authentication/authentication-view"
import Person from 'Frontend/generated/oxynoia/documents/collection/b2b/model/Person.js';


@customElement('authentication-view')
export class AuthenticationView extends MobxLitElement implements BeforeEnterObserver {

    collectId: string = "";

    redirect: string = "";

    @state()
    subscriber!: Person;

    _signatureRequestId: string | null = "";

    _from: string | null = "";

    @state()
    authCode = '';

    @state()
    authCodeChannelChoice = '';

    @state()
    authCodeSent: boolean = false

    @property()
    email: String = "";

    onBeforeEnter(location: RouterLocation) {
        this.collectId = location.params.collectId as string;
        this.redirect = location.params.redirect as string;
        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("from")) {
                this._from = searchParams.get("from");
            }
        }
    }

    render() {
        return html`
        ${this.subscriber
                ? html`
            <div class="cdui-centered-page">
              <div class="cdui-card">
              ${this.authCodeSent
                        ? html`
                    <vaadin-form-layout>
                      <vaadin-text-field 
                        required
                        autofocus
                        autocapitalize="characters"
                        minlength=6
                        maxlength=6
                        style="max-width: 8em; font-size: 1.4em;"
                        label="Code d'authentification"
                        @keyup="${(e: KeyboardEvent) => {
                                if (e.key == "Enter") {
                                    this.validateAuthCode();
                                }
                            }}"
                        @value-changed=${this.authCodeChanged}
                      ></vaadin-text-field>
                    </vaadin-form-layout>
                    <div style="display: flex; gap: 10px; margin-top: 20px;">
                      <cdui-button @click="${this.validateAuthCode}">
                        Valider
                      </cdui-button>
                      <cdui-button @click="${() => this.authCodeSent = false}">
                        Nouveau code
                      </cdui-button>
                    </div>
                `
                        : html`
                    <strong>Bonjour ${this.subscriber.firstName}</strong>
                    <p>Nous devons vérifier que c'est bien vous avant de continuer. Cliquez sur le bouton ci-dessous
                    pour recevoir un code d'authentification par email à <b>${this.obfuscateEmail(this.subscriber.email as string)}</b> et sur votre téléphone au <b>${this.obscutePhoneNumber(this.subscriber.phoneNumber as string)}</b></p>
                    <vaadin-horizontal-layout theme="spacing">
                      <cdui-button
                        id="send-auth-code-button"
                        @click="${this.sendAuthCode}"
                      >Envoyer</cdui-button>
                    </vaadin-horizontal-layout>
                `
                    }
              </div>
            </div>
          `
                : html`
              Veuillez patienter pendant que nous cherchons votre dossier...
              <div class="v-loading-indicator"></div>
            `
            }
      `;
    }

    obscutePhoneNumber(phoneNumber: string): string {
        if (phoneNumber.length > 4) {
            return `${phoneNumber.substring(0, 2)}***${phoneNumber.substring(phoneNumber.length - 2)}`;
        }
        return phoneNumber;
    }

    obfuscateEmail(email: string): string {
        const obfuscatedEmail = email.length > 6
            ? `${email.substring(0, 3)}***${email.substring(email.length - 6)}`
            : email;

        return obfuscatedEmail;
    }


    valueChanged(e: CustomEvent) {
        this.authCodeChannelChoice = e.detail.value;
    }

    authCodeChanged(e: CustomEvent) {
        this.authCode = e.detail.value;
    }

    sendAuthCode() {
        if (this.subscriber != undefined) {
            AuthEndpoint.sendAuthCode(
                this.collectId,
                this.subscriber.email,
                this.subscriber.phoneNumber,
            )
            this.authCodeSent = true
        }
    }

    async validateAuthCode() {
        if (this.subscriber != undefined && this.subscriber.id != undefined) {
            AuthEndpoint.validateAuthCode(
                this.collectId,
                this.authCode,
            ).then(response => {
                if (response && response?.length > 0) {
                    window.sessionStorage.setItem("auth-data", response);
                    Router.go(this.redirect +'/'+this.collectId);
                } else {
                    Notification.show("Erreur d'authentification.");
                }
            })
        }
    }

    async getSubscription() {
        const serverResponse = await SubscriptionEndpoint.getSubscriber(this.collectId, this._from != null ? this._from : "")
        if (serverResponse != undefined) {
            this.subscriber = serverResponse;
        } else {
            Notification.show("Dossier non trouvé.")
        }
    }

    connectedCallback() {
        super.connectedCallback();

        if (location.search) {
            let searchParams = new URLSearchParams(location.search);
            if (searchParams.has("from")) {
                this._from = searchParams.get("from");
            }
            if (searchParams.has("signature_request_id")) {
                this._signatureRequestId = searchParams.get("signature_request_id");
            }
        }

        this.classList.add(
            'flex',
            'flex-col',
            'gap-m',
            'h-full',
            'w-full',
        );
        this.getSubscription();
    }

    createRenderRoot() {
        return this;
    }

}