import { LitElement, PropertyValues, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import "../pdf-viewer";
import '@vaadin/icons';
import { DocumentEndpoint } from "Frontend/generated/endpoints";

@customElement("document-preview")
export class DocumentPreview extends LitElement {

    @state()
    documentType: DocumentType = DocumentType.NONE;

    @state()
    data: string = "";

    @state()
    isValid?: boolean;

    @query("canvas")
    canvas?: HTMLCanvasElement;

    docId!: number;

    @property()
    filename: string | null = null;

    render() {
        switch (this.documentType) {
            case DocumentType.PDF:
                return html`
                    <vaadin-vertical-layout>
                        ${this.isValid !== undefined && this.isValid ? html`<vaadin-icon class="valid" icon="vaadin:check"></vaadin-icon>` : html``}
                        <pdf-viewer style="align-self:center" .data=${this.data}></pdf-viewer>
                    </vaadin-vertical-layout>
                `;
            case DocumentType.IMAGE:
                return html`
                    <vaadin-vertical-layout>
                        ${this.isValid !== undefined && this.isValid ? html`<vaadin-icon class="valid" icon="vaadin:check"></vaadin-icon>` : html``}
                        <canvas width="147" height="210" @click=${() => this.displayFile()} style="cursor:pointer"></canvas>
                        ${this.filename !== null ? html`<span title="${this.filename}" class="file-info">${this.filename}</span>`: html``}
                    </vaadin-vertical-layout>
                `;
            default:
                return html`<vaadin-icon icon="vaadin:file-text-o" style="height:175px;width:147px;opacity:0.2;"></vaadin-icon>`;
        }
    }

    private displayFile(){
        if(this.docId && window.sessionStorage.getItem("auth-data") != null){
            DocumentEndpoint.getData(this.docId, window.sessionStorage.getItem("auth-data") as string).then(response => {
                if(response){
                    var byteCharacters = atob(response);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
            });
        }
    }

    updated(changedProperties: PropertyValues<this>) {
        changedProperties.forEach((oldValue, propName) => {
            if (propName == 'documentType' && this.documentType == DocumentType.IMAGE) {
                //load image
                this.loadImage();
            }
            if(propName == "data" && oldValue != ""){
                this.loadImage();
            }
        });
        return true;
    }

    private loadImage(){
        var ctx = this.canvas?.getContext('2d');
        var img = new Image();
        img.onload = () => {
            var ratio = img.width / img.height;
            if (ratio > 1) {
                ctx?.drawImage(img, 0, 0, 147, (147 * (1 / ratio)));
            } else {
                ctx?.drawImage(img, 0, 0, 147, 210 * ratio);
            }
        }
        img.src = this.data;
    }

    static get styles() {
        return [
            css`
                vaadin-icon.valid {
                    top: 50%;
                    font-size: 3em;
                    width: 100%;
                    color: var(--dark-green);
                    position:absolute;
                }
                vaadin-vertical-layout {
                    position:relative;
                }
                img {
                    max-width:180px;
                }
                .file-info{
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 147px;
                }
            `
        ];
    }

}
export enum DocumentType {
    NONE,
    PDF,
    IMAGE
}