import { SubscriptionEndpoint } from "Frontend/generated/endpoints";
import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";
import { appStore } from "Frontend/stores/app-store";
import { html, LitElement } from "lit";
import { customElement, state, query } from 'lit/decorators.js';
import '@cdui/button';
import { Notification } from '@vaadin/notification';
import { Binder, field } from "@hilla/form";
import '@vaadin/email-field';
import { FormLayoutResponsiveStep } from "@vaadin/form-layout";
import { TextField } from "@vaadin/text-field";
import { Router } from "@vaadin/router";
import Person from "Frontend/generated/oxynoia/documents/collection/b2b/model/Person";

@customElement("edit-user-information")
class EditUserInformationView extends LitElement {

    @state()
    collectId: string = "";

    @state()
    subscription: Subscription = {};

    @query("#lastName")
    private lastName!: TextField;
    @query("#firstName")
    private firstName!: TextField;
    @query("#email")
    private email!: TextField;
    @query("#phoneNumber")
    private phoneNumber!: TextField;

    @state()
    private isFormValid: boolean = true;

    @state()
    private updateInProgress: boolean = false;

    render() {
        return html`
            <div class="cdui-page">

                <div style="width: 100%;">
                    <h1 class="cdui-title">Corriger mes informations</h1>
                    <div class="cdui-card" style="width: calc(100% - 60px);">

                    <vaadin-form-layout .responsiveSteps="${this.responsiveSteps}">
                        <vaadin-text-field label="Prénom" 
                            id="firstName"
                            required
                            error-message="Le prénom est obligatoire" 
                            .value="${this.subscription.subscriber?.firstName}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.firstName = e.detail.value
        })}"
                            clear-button-visible>
                        </vaadin-text-field>
                        <vaadin-text-field label="Nom" 
                            required
                            id="lastName"
                            error-message="Le nom de famille est obligatoire" 
                            .value="${this.subscription.subscriber?.lastName}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.lastName = e.detail.value
        })}"
                            clear-button-visible>
                        </vaadin-text-field>
                        <vaadin-email-field
                            id="email"
                            error-message="L'email est obligatoire"
                            .value="${this.subscription.subscriber?.email}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.email = e.detail.value
        })}"
                            required
                            label="Email"
                        ></vaadin-email-field>
                        <vaadin-text-field
                            id="phoneNumber"
                            min-length="5"
                            max-length="18"
                            required
                            pattern="^[+]?[0-9]{2,3}[0-9]{5,10}$"
                            allowed-char-pattern="[\\d+]"
                            error-message="Le numéro de téléphone est obligatoire ou invalide."
                            label="Téléphone"
                            helper-text="Format: +33664872488 ou 0664872488"
                            .value="${this.subscription.subscriber?.phoneNumber}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.phoneNumber = e.detail.value
        })}"
                            >
                            <vaadin-icon slot="prefix" icon="vaadin:phone"></vaadin-icon>
                        </vaadin-text-field>
                        <h4 colspan="3">Adresse</h4>
                        <vaadin-text-field id="address" 
                            .value="${this.subscription.subscriber?.address}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.address = e.detail.value
        })}"
                            label="Adresse" colspan="3">
                                <vaadin-icon slot="prefix" icon="vaadin:home"></vaadin-icon>
                        </vaadin-text-field>
                        <vaadin-text-field id="addressComp" 
                            .value="${this.subscription.subscriber?.additionnalAddress}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.additionnalAddress = e.detail.value
        })}"
                        label="Complément d'adresse" colspan="3">
                        <vaadin-icon slot="prefix" icon="vaadin:home"></vaadin-icon>
                        </vaadin-text-field>
                        <vaadin-text-field 
                            id="zipCode"
                            .value="${this.subscription.subscriber?.zipCode}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.zipCode = e.detail.value
        })}"
                            allowed-char-pattern="[0-9]"
                            label="Code postal">
                        </vaadin-text-field>
                        <vaadin-text-field id="city" 
                            .value="${this.subscription.subscriber?.city}"
                            @value-changed="${(e: CustomEvent) => this.checkUpdate(() => {
            if (this.subscription.subscriber)
                this.subscription.subscriber.city = e.detail.value
        })}"
                            label="Ville"></vaadin-text-field>
                    </vaadin-form-layout>
                    <vaadin-progress-bar indeterminate .hidden="${!this.updateInProgress}"></vaadin-progress-bar>
                    <cdui-button
                            ?hidden=${this.updateInProgress}
                            @click="${() => { if (this.isFormValid) this.savePerson() }}"
                            ?disabled="${!this.isFormValid}"
                    >
                        Mettre à jour mes informations
                    </cdui-button>
                </div>
                </div>

            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.collectId = location.pathname.split("/").pop() as string;

        this.classList.add(
            'w-full',
            'h-full',
        );

        this.getSubscription();
    }

    private savePerson() {
        this.updateInProgress = true;
        SubscriptionEndpoint.updateSubscriber(this.subscription.id as number, this.subscription.subscriber as Person).then(() => {
            this.updateInProgress = false;
            //Notify, then redirect
            Notification.show("Vos coordonnées ont bien été mises à jour", { position: "top-center", duration: 3000, theme: "success" });
            Router.go("docs/" + this.subscription.collectId);
        }, () => {
            this.updateInProgress = false;
            //Notify error message
            Notification.show("Une erreur est survenue lors de la mise à jour, veuillez réessayer svp", { position: "top-center", duration: 3000, theme: "error" });
        });
    }

    protected createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }

    async getSubscription() {
        var serverResponse = await SubscriptionEndpoint.getSubscriptionByCollectId(this.collectId, "", window.sessionStorage.getItem("auth-data") as string);
        if(serverResponse == undefined || serverResponse == null){
            window.sessionStorage.removeItem("auth-data");
                Router.go('/auth/'+encodeURIComponent(this.collectId)+"/userInfo");
        }
        if (serverResponse != undefined) {
            this.subscription = serverResponse;
        } else {
            Notification.show("Dossier non trouvé.")
        }
    }

    private checkUpdate(fn: () => void) {
        fn();
        this.isFormValid = this.isValid();
    }

    private responsiveSteps: FormLayoutResponsiveStep[] = [
        // Use one column by default
        { minWidth: 0, columns: 1 },
        // Use two columns, if layout's width exceeds 500px
        { minWidth: '500px', columns: 3 },
    ];

    public isValid(): boolean {
        return this.lastName.validate()
            && this.firstName.validate()
            && this.email.validate()
            && this.phoneNumber.validate();
    }


}