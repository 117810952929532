import { EndpointRequestInit as EndpointRequestInit_1 } from "@hilla/frontend";
import client_1 from "./connect-client.default.js";
import type Person_1 from "./oxynoia/documents/collection/b2b/model/Person.js";
import type Subscription_1 from "./oxynoia/documents/collection/b2b/model/Subscription.js";
async function getSubscriber_1(collectId: string, from: string | undefined, init?: EndpointRequestInit_1): Promise<Person_1 | undefined> { return client_1.call("SubscriptionEndpoint", "getSubscriber", { collectId, from }, init); }
async function getSubscriptionByCollectId_1(collectId: string, from: string | undefined, authData: string | undefined, init?: EndpointRequestInit_1): Promise<Subscription_1 | undefined> { return client_1.call("SubscriptionEndpoint", "getSubscriptionByCollectId", { collectId, from, authData }, init); }
async function refuseToValidateInformations_1(collectId: string, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("SubscriptionEndpoint", "refuseToValidateInformations", { collectId }, init); }
async function updateSubscriber_1(subscriptionId: number, subscriber: Person_1, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("SubscriptionEndpoint", "updateSubscriber", { subscriptionId, subscriber }, init); }
async function updateSubscriptionStatus_1(collectId: number, status: string, init?: EndpointRequestInit_1): Promise<void> { return client_1.call("SubscriptionEndpoint", "updateSubscriptionStatus", { collectId, status }, init); }
export { getSubscriber_1 as getSubscriber, getSubscriptionByCollectId_1 as getSubscriptionByCollectId, refuseToValidateInformations_1 as refuseToValidateInformations, updateSubscriber_1 as updateSubscriber, updateSubscriptionStatus_1 as updateSubscriptionStatus };
