import { DialogOpenedChangedEvent } from "@vaadin/dialog";
import { dialogRenderer } from "@vaadin/dialog/lit";
import { Upload, UploadAbortEvent, UploadFile, UploadResponseEvent } from "@vaadin/upload";
import { FileUploadEndpoint } from "Frontend/generated/endpoints";
import Subscription from "Frontend/generated/oxynoia/documents/collection/b2b/model/Subscription";
import { applyTheme } from "Frontend/generated/theme";
import { frenchUploadI18n } from "Frontend/utils/french-i18n";
import { LitElement, TemplateResult, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import '@vaadin/dialog';
import './multi-pages-selector';
import { MultiPagesSelector, PagesSelectedEvent, PreviewData } from "./multi-pages-selector";
import { DocumentType } from "./document-preview";
import '@cdui/button';


@customElement("global-drop-zone")
class GlobalDropZone extends LitElement {

    @property()
    collectId!: string;

    @property({ type: Object })
    subscription!: Subscription;

    @state()
    private _statusText: string = "";

    @state()
    private _displayStatus: boolean = true;

    @state()
    private _displayProgress: boolean = false;

    @state()
    private _dialogOpened = false;

    @query("vaadin-upload")
    private upload!: Upload;

    private _tIdByUploads: Map<String, String> = new Map();
    private _intervalByUploads: Map<String, NodeJS.Timeout> = new Map();
    private _intervalByTids: Map<String, NodeJS.Timeout> = new Map();

    render() {
        return html`
            <p>Si vous souhaitez remplir les documents en déposant un pdf contenant plusieurs pièces : </p>
            <div class="globalFileDrop">
                <vaadin-upload class="cdui-file-upload globalFileDrop" 
                        .i18n=${frenchUploadI18n}
                        id="upload_all"
                        target="doc/uploadall"
                        class="upload-all-cls"
                        accept="application/pdf,.pdf,image/jpeg,.jpeg,.jpg,image/png,.png,.jpg,image/tiff,.tiff,.tif"
                        .headers="${{ 'X-TRANSACTION-ID': 'ClicDossier-Global-Tid', 'X-COLLECT-ID': this.collectId , 'X-AUTH-DATA': window.sessionStorage.getItem("auth-data") }}"
                        @upload-response=${(e: UploadResponseEvent) => this.handleResponseAll(e, "ClicDossier-Global-Tid", "ClicDossier-Global-Tid")}
                        @file-abort=${(e: UploadAbortEvent) => this.handleFileUploadAbort(e)}
                >
                    <span ?hidden=${!this._displayStatus}>${this._statusText}</span>
                    <div id="progress-all" class="progress-all-cls" ?hidden=${!this._displayProgress}>
                        <vaadin-progress-bar indeterminate></vaadin-progress-bar>
                    </div>
                    <span slot="drop-label">Déposez ici un pdf contenant de multiples documents pour remplir automatiquement les cases</span>
                </vaadin-upload>
                <div style="display:flex; justify-content:center">
                    <cdui-button @click=${(e: CustomEvent) => { e.preventDefault(); this._dialogOpened = true; }}>Créer un document avec plusieurs photos</cdui-button>
                </div>
                <vaadin-dialog
                    style="width:100%"
                    header-title="Créer un document à partir d'images"
                    .opened="${this._dialogOpened}"
                    @opened-changed="${(event: DialogOpenedChangedEvent) => {
                this._dialogOpened = event.detail.value;
            }}"
                    ${dialogRenderer(() => html`<multi-page-selector
                        @pages-selected=${this.uploadPages}
                        .itemRenderer=${(item: PreviewData) => {
                    return html`
                                <document-preview 
                                    class="previewCls" 
                                    .data=${item.data} 
                                    .filename=${item.filename}
                                    .documentType=${DocumentType.IMAGE}>
                                </document-preview>
                            `;
                }}
                        >
                    </multi-page-selector>`, [])}
                ></vaadin-dialog>
            </div>
        `;
    }

    private uploadPages(e: PagesSelectedEvent) {
        this._dialogOpened = false;
        FileUploadEndpoint.uploadPages(this.collectId, "ClicDossier-Global-Tid", e.detail.pages.map(page => {
            return {
                filename: page.filename,
                data: page.data.split(',')[1]
            }
        })).then(response => {
            if(response !== undefined){
                (e.target as MultiPagesSelector).clearSelections();
                this.handleResponsePages(e.detail.pages[0].filename,  "ClicDossier-Global-Tid", response);
            }
        });
    }

    handleResponsePages(filename: string, transactionId: string, generatedTid: string){
        console.log("Handling " + transactionId + " --- " + transactionId);

        this._statusText = 'Votre document est en train d\'être découpé...';
        console.log("Status Elements is being updated : Votre document est en train d\'être découpé");

        var transactionIdWithSuffix = generatedTid;
        this._tIdByUploads.set(filename, transactionIdWithSuffix);
        console.log("Handling response " + transactionIdWithSuffix);

        this._displayProgress = true;

        var newInterval = setInterval(this.checkResponseGlobal, 1200, this, transactionIdWithSuffix);
        this._intervalByUploads.set(filename, newInterval);
        this._intervalByTids.set(transactionIdWithSuffix, newInterval);
        setTimeout(this.responseTimout, 1800000, this, newInterval);
    }

    async handleResponseAll(e: UploadResponseEvent, transactionId: string, suffix: string) {
        console.log("Handling " + transactionId + " --- " + suffix);
        if (e.detail.xhr.status == 200) {

            this._statusText = 'Votre document est en train d\'être découpé...';
            console.log("Status Elements is being updated : Votre document est en train d\'être découpé");

            var transactionIdWithSuffix = e.detail.xhr.responseText;
            this._tIdByUploads.set(e.detail.file.name, transactionIdWithSuffix);
            console.log("Handling response " + transactionIdWithSuffix);

            this._displayProgress = true;

            var newInterval = setInterval(this.checkResponseGlobal, 1200, this, transactionIdWithSuffix);
            this._intervalByUploads.set(e.detail.file.name, newInterval);
            this._intervalByTids.set(transactionIdWithSuffix, newInterval);
            setTimeout(this.responseTimout, 1800000, this, newInterval);
        } else {
            //error management
            // Notification.show("Oops, something went wrong.")
        }
    }

    handleFileUploadAbort(e: UploadAbortEvent) {
        if (this._tIdByUploads.has(e.detail.file.name) && this._tIdByUploads.get(e.detail.file.name) !== undefined) {
            var tid = this._tIdByUploads.get(e.detail.file.name);
            FileUploadEndpoint.cancelFile(this.collectId, tid as string).then(rsp => {
                if (this._intervalByUploads.has(e.detail.file.name) && this._intervalByUploads.get(e.detail.file.name) !== undefined) {
                    var foundInterval = this._intervalByUploads.get(e.detail.file.name);
                    clearInterval(foundInterval);
                    this._intervalByUploads.delete(e.detail.file.name);
                    this._intervalByTids.delete(tid as string);
                }
                if (this._intervalByTids.size == 0) {
                    this._statusText = '';
                    this._displayProgress = false;
                }
            });
            this._tIdByUploads.delete(e.detail.file.name);
        }

    }

    responseTimout(parent: GlobalDropZone, intervalId: NodeJS.Timeout) {
        clearInterval(intervalId);
        parent._displayProgress = false;
        parent._statusText = "Une erreur est survenue lors du traitement de votre document, veuillez réessayer svp.";
    }


    checkResponseGlobal(parent: GlobalDropZone, transactionId: string) {
        FileUploadEndpoint.fileHasResponseGlobal(transactionId).then((hasResponse: boolean) => {
            if (hasResponse) {
                FileUploadEndpoint.getFileResponseGlobal(transactionId).then(rsp => {
                    // matchCat = match;
                    if (parent._intervalByTids.has(transactionId) && parent._intervalByTids.get(transactionId) !== undefined) {
                        clearInterval(parent._intervalByTids.get(transactionId));
                        parent._intervalByTids.delete(transactionId);
                    }
                    if (parent._intervalByTids.size == 0) {
                        parent._statusText = '';
                        parent._displayProgress = false;
                    }

                    var chkdc = JSON.parse(rsp!);
                    console.log(chkdc);

                    if (chkdc.status != "OK") {
                        console.log("No matching request");
                        return;
                    }

                    var nbasked = 0;
                    var nbnew = 0;

                    for (const [idxdoc, transid] of Object.entries(chkdc.docToReqDoc)) {
                        var doc = chkdc.ggtResp.documents[idxdoc];
                        console.log("----");
                        console.log(doc);
                        console.log(transid);

                        if (parent.subscription.missingDocuments !== undefined) {
                            if (parent.subscription.missingDocuments?.filter(rd => rd?.id == transid).length > 0) {
                                nbasked = nbasked + 1;
                            } else {
                                nbnew = nbnew + 1;
                            }
                        }

                    }
                    if (nbnew == 0) {
                        parent._statusText = `${nbasked} documents ont été intégrés dans les documents demandés ci-dessous.`;
                    } else {
                        parent._statusText = `${nbasked} documents ont été intégrés dans les documents demandés ci-dessous. ${nbnew} documents additionnels ont été ajoutés`;
                    }

                    parent.subscriptionStateChanged();
                })
            }
        });
    }

    subscriptionStateChanged() {
        this.dispatchEvent(new CustomEvent('subscription-changed', {}));
    }

    connectedCallback(): void {
        super.connectedCallback();
        applyTheme(this.renderRoot);
    }


}