import { html, LitElement, css } from "lit";
import { customElement, state } from "lit/decorators.js";

@customElement("cdui-documents-section-header-v2")
class CduiDocumentsSectionHeader extends LitElement {

    @state()
    private number: number = 1;
    

    render() {
        return html`
             <div id="container">
                <div id="title">
                    <slot>PIÈCES D’IDENTITÉ</slot>
                </div>

                <div id="pastille" class="${this.number == 0 ? 'valid' : 'to-do'}">
                    ${this.number == 0 ? '' : this.number}
                </div>
            </div>
        `;
    }


    static styles = css`
        @import url('https://fonts.googleapis.com/css2?family=Quicksand');

        #container {
            width: 100%;
            height: 43px;
            padding: 0 11px 0 20px;

            color: white;
            background: #006F8E;
            border-radius: 22px;
            box-shadow: 0px 3px 6px #00000029;

            display: flex;
            align-items: center;
            justify-content: space-between;

            font-family: 'Quicksand', sans-serif;
        }

        #title {
            font-size: 1.25em;
            font-weight: bold;
            text-align: center;
        }

        @media (max-width: 600px) {
            #title {
                font-size: 1em;
            }
        }


        .valid{
            background: #a1c96e;
        }

        .to-do{
            background: #EF8970;
        }

        #pastille {
            width: 25px;
            height: 25px;
            border-radius: 50%;

            font-size: 15px;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    `;


}